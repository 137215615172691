<template>
    <div class="completePaymentClass">
        <section class="centerDetail">
            <!-- 选择套餐按钮 -->
            <div class="returnBtn" @click="returnBack()">
                <img src="@/assets/images/storeManage/left_arrow.png" alt/>
                <span>选择套餐</span>
            </div>
            <!--提示语-->
            <div>
                <div class="titleBorder">
                    <span>订单创建成功！请完成付款</span>
                </div>
            </div>
            <!--订单信息-->
            <div class="orderInfo">
                <p>订单信息</p>
                <div class="margin-b20">
                    <span class="orderTitle">购买产品：</span>
                    <span class="orderContent">2个店铺-套餐-1个月</span>
                </div>
                <div>
                    <span class="orderTitle">订单号：</span>
                    <span class="orderContent">2020081212121212</span>
                </div>
            </div>
            <!--支付方式-->
            <div class="orderInfo">
                <p>支付方式</p>
                <el-radio v-model="payRadio" label="1">
                    <img src="@/assets/images/storeManage/Paypal.png" width="152" height="40" alt="">
                </el-radio>
            </div>
            <div class="payMoney">
                <span class="payText">支付金额：</span>
                <span class="payNum">$198.00</span>
                <span class="nextStep">付款</span>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "completePayment",
        data() {
            return {
                payRadio: '1'
            }
        },
        methods: {
            returnBack() {
                this.$router.push({path: 'choosePackage'})
            }
        },
    }
</script>

<style scoped lang="scss">
    .completePaymentClass {
        .margin-b20 {
            margin-bottom: 20px;
        }
        .centerDetail {
            margin-top: 51px
        }
        .returnBtn {
            cursor: pointer;
        }
        .titleBorder {
            font-size: 32px;
            font-weight: 500;
            color: #312F2E;
            padding-bottom: 20px;
            border-bottom: 2px solid #E2D6CB;
        }
        .orderInfo {
            padding: 39px 0;
            border-bottom: 2px solid #E2D6CB;
            p {
                color: #312F2E;
                font-size: 21px;
                margin-bottom: 20px;
            }
            .orderTitle {
                display: inline-block;
                width: 120px;
                font-size: 18px;
            }
            .orderContent {
                color: #84807B;
                font-size: 18px;
            }
        }
        .payMoney {
            text-align: right;
            margin-top: 60px;
            .payText {
                font-size: 20px;
                color: #312F2E
            }
            .payNum {
                font-size: 32px;
                font-weight: bold;
                color: #312F2E
            }
            .nextStep {
                width: 160px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                display: inline-block;
                background: #54AB8E;
                border-radius: 8px;
                font-size: 20px;
                font-weight: 500;
                color: #FFFFFF;
                margin-left: 20px;
                cursor: pointer;
            }
        }

    }
</style>